@import '~/src/assets/scss/variables.scss';
.header {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;

  header {
    background: $ciety-bright-60;
    border-radius: 999px;
    display: flex;
    justify-content: space-between;
    backdrop-filter: blur(40px);
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 0 20px;
    @include maxScreen(md) {
      background: $ciety-bright-80;
      height: 56px;
      padding: 0 15px;
    }
  }

  .cietyLogo {
    svg {
      height: 32px;
      @media screen and (max-width: 1024px) {
        height: 25px;
      }
      @include maxScreen(md) {
        height: 32px;
      }
    }
  }

  .pcMenu {
    display: flex;
    column-gap: 40px;
    @media screen and (max-width: 1024px) {
      column-gap: 15px;
    }
    li {
      @include font(16px, 500);
      @media screen and (max-width: 1024px) {
        @include font(15px, 500);
      }
      a.active,
      a:hover {
        padding-bottom: 4px;
        border-bottom: 2px solid;
      }
    }
    @include maxScreen(md) {
      display: none;
    }
  }

  .rightSection {
    display: flex;
    gap: 12px;
    @include maxScreen(md) {
      gap: 0;
    }
  }

  .link {
    color: $ciety-wh;
    background: $ciety-bk;
    @include font(16px, 500);
    display: flex;
    border-radius: 999px;
    padding: 12px 27px;
    &:hover {
      background: $ciety-wh;
      color: $ciety-bk;
      &.black {
        background: $ciety-lg;
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 16px;
    }
    @include maxScreen(md) {
      display: none;
    }
  }

  .internationalization {
    backdrop-filter: none;
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $ciety-dk;
    @include font(16px, 500);
    @include maxScreen(md) {
      @include font(14px, 500);
    }
  }

  .modal {
    overflow: hidden;
    &.open {
      //animation: fadeInDown 1s ease-in-out;
    }
    &.close {
      display: none;
    }
    position: absolute;
    top: 60px;
    right: 120px;
    @media screen and (max-width: 1024px) {
      right: 100px;
    }
    @include maxScreen(md) {
      right: 50px;
      top: 50px;
    }
    ul {
      backdrop-filter: blur(20px);
      background: $ciety-bright-80;
      border: 1px solid $ciety-lg;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 12px 8px;
      li {
        @include font(16px);
        padding: 4px 8px;
        border-radius: 8px;
        &:hover {
          background: #13131a1a;
        }
      }
    }
    &.black {
      ul {
        background: $ciety-bright-10;
        li {
          color: $ciety-wh;
          &:hover {
            background: $ciety-bright-10;
          }
        }
      }
    }
  }

  .mobileMenu {
    z-index: -1;
    backdrop-filter: blur(40px);
    position: absolute;
    top: 66px;
    width: 100%;
    padding: 16px 20px;
    border-radius: 24px;
    background: $ciety-bright-60;
    left: 50%;
    transform: translateX(-50%);
    li a {
      display: block;
      @include font(16px, 500);
      color: $ciety-bk;
      padding: 12px 0;
      border-bottom: 0.75px solid $ciety-bk;
    }
    .buttonContainer {
      margin-top: 16px;
      gap: 8px;
      display: flex;
      button {
        @include font(16px);
        padding: 12px 0;
        border-radius: 24px;
        width: 100%;
        text-align: center;
      }
      .downloadBtn {
        color: $ciety-wh;
        background: $ciety-bk;
        &.white {
          color: $ciety-bk;
          background: $ciety-wh;
        }
      }
    }
  }

  &.black {
    header {
      background: $ciety-bright-10;
      @include maxScreen(md) {
        background: $ciety-bright-60;
      }
    }
    a svg,
    ul li,
    .mobileMenu li a,
    .menu {
      color: $ciety-lg;
    }
    .internationalization {
      color: $ciety-lg-80;
    }
    .mobileMenu {
      background: $ciety-bright-10;
      li:not(:last-child) {
        border-bottom: 1px solid $ciety-lg-80;
      }
    }
    a.active {
      border-bottom: 2px solid $ciety-lg;
    }
    .downloadBtn {
      background: $green;
      color: $gray-dark;
    }
  }
}

.comma {
  position: relative;
  z-index: 11;
  left: 0;
  @include container;
  @include vh(height, 1300, 800);
  @include vh(top, 430);
  .sequence {
    width: 100%;
    height: 100%;
    img {
      position: relative;
      height: 100%;
      object-fit: cover;
      overflow: visible;
      transform: translateX(-50%);
    }
  }
  @include maxScreen(xl) {
    left: 0;
  }
  @include maxScreen(lg) {
    left: 0;
  }
}

.clever {
  position: absolute;
  bottom: 15%;
  right: 0%;
  z-index: 1;
  @include vh(height, 1350, 800);
  @include vh(top, 200, 100);
  .sequence {
    width: 100%;
    height: 100%;
    img {
      position: relative;
      height: 100%;
      object-fit: cover;
      overflow: visible;
      transform: translateX(50%);
    }
  }
  @include maxScreen(xl) {
    right: 7%;
  }
  @include maxScreen(lg) {
    right: 10%;
  }
}

.room {
  @apply absolute inset-0;

  > img {
    @apply absolute;
  }

  img:nth-child(1) {
    top: 18.12%;
    left: 53.5%;
    width: 300px;
  }

  img:nth-child(2) {
    left: 29.5%;
    top: 23.27%;
    width: 234px;
  }

  img:nth-child(3) {
    left: 31.66%;
    top: 45.04%;
    width: 240px;
  }

  img:nth-child(4) {
    left: 67.63%;
    top: 30.23%;
    width: 274px;
  }

  img:nth-child(5) {
    left: 65.82%;
    top: 56.35%;
    width: 427px;
  }

  img:nth-child(6) {
    left: 51.84%;
    top: 46.13%;
    width: 444px;
  }

  img:nth-child(7) {
    left: 6.7%;
    top: 57.95%;
    width: 203px;
  }

  img:nth-child(8) {
    left: 16.61%;
    top: 31.85%;
    width: 379px;
  }

  img:nth-child(9) {
    left: 12.74%;
    top: 73.19%;
    width: 180px;
  }

  img:nth-child(10) {
    left: 46.33%;
    top: 16.74%;
    width: 203px;
  }

  img:nth-child(11) {
    left: 80.04%;
    right: 6.77%;
    top: 59.31%;
    bottom: 14.36%;
    width: 338px;
  }

  img:nth-child(12) {
    left: 18.84%;
    right: 72.67%;
    top: 70.71%;
    bottom: 14.34%;
    width: 218px;
  }
}

.sectionHeader {
  text-align: center;
  .icon {
    width: 54px;
    margin: 0 auto 12px;
  }
  h3 {
    font-family: Favorit;
    @include vh(margin-bottom, 30);
    @include font(48px, 700, 1.2);
    color: $ciety-dk;
    white-space: pre-wrap;
    &.en {
      font-weight: 500;
    }
  }
  p {
    @include r-font(20, 350, 1.5);
    color: $ciety-dk;
    white-space: pre-wrap;
  }
  &.white {
    h3,
    p {
      color: #fbfbfb;
    }
  }
  &.left {
    text-align: left;
  }
  @include maxScreen(md) {
    .icon {
      width: 33px;
      margin: 0 auto 8px;
    }
    h3 {
      @include font(24px, 700, 1.3);
      margin-bottom: 20px;
    }
    p {
      @include font(14px, 500, 1.4);
    }
  }
}

.leaders {
  @apply flex flex-nowrap justify-start items-end;
  overflow: hidden;

  @include maxScreen(md) {
    margin-top: 40px;
  }

  .leader {
    position: relative;
    @include vh(height, 650);
    aspect-ratio: 340 / 650;
    img {
      height: 100%;
    }
    .still {
      display: block;
    }
  }
}

//footer
footer {
  background: $ciety-dk;
  .footerContainer {
    @include container;
    padding-top: 80px;
    padding-bottom: 80px;
    @include maxScreen(md) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
//footer-top
.top {
  display: flex;
  height: 156px;
  @include maxScreen(md) {
    flex-direction: column;
    height: auto;
  }
  .slogan {
    width: 50%;
    flex-shrink: 0;
    @include maxScreen(md) {
      width: 100%;
      margin-bottom: 40px;
    }
    h3 {
      @include font(18px, 350);
      color: $ciety-wh;
      @include maxScreen(md) {
        @include font(18px, 350, 1.5);
      }
    }
  }
  .groups {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: start;
    @include maxScreen(md) {
      width: 100%;
      justify-content: space-between;
    }
    .group {
      h4 {
        margin-bottom: 16px;
        @include font(14px);
        color: $ciety-wh;
        @include maxScreen(md) {
          margin-bottom: 10px;
          @include font(14px, 500, 1.5);
        }
      }
      ul {
        li {
          margin-bottom: 4px;
          @include maxScreen(md) {
            margin-bottom: 10px;
          }
          a {
            @include font(20px);
            color: $ciety-wh;
            @include maxScreen(md) {
              @include font(14px);
            }
          }
        }
      }
    }
  }
}
//footer-logo
.logo {
  margin-top: 40px;
  color: $ciety-lg;
  height: 80px;
  width: 80px;
  @include maxScreen(md) {
    height: 60px;
    width: 60px;
  }
}
//footer-etc
.etc {
  display: flex;
  color: $ciety-wh;
  margin-top: 40px;
  @include maxScreen(md) {
    flex-direction: column;
    margin-bottom: 50px;
  }
  p {
    width: 50%;
    flex-shrink: 0;
    @include font(16px);
    @include maxScreen(md) {
      width: 100%;
      @include font(12px, 350);
    }
  }
  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @include maxScreen(md) {
      justify-content: flex-start;
      width: 100%;
    }
    .links {
      a {
        margin-right: 20px;
        @include font(14px);
        @include maxScreen(md) {
          @include font(12px, 350);
          margin-right: 8px;
        }
      }
    }
    .lang {
      @include font(16px);
      @include maxScreen(md) {
        display: none;
      }
    }
  }
}

.store {
  @include vh(margin-top, 220);
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .merches {
    img {
      @include vh(height, 260);
      margin: 0 auto;
      max-width: none;
    }
    img + img {
      @include vh(margin-top, 60);
    }

    @include maxScreen(md) {
      img {
        position: relative;
        left: 50%;
        height: 130px;
        max-width: none;
        transform: translateX(-50%);
      }
    }
  }

  .txts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    p {
      position: absolute;
      display: inline-block;
      @include r_font(28, 350, 1.3);
      padding: 20px 30px;
      color: #1c1c24;
      white-space: pre-wrap;
      background: rgba(251, 251, 251, 0.8);
      //backdrop-filter: blur(20px);
      border-radius: 30px;
    }
    .p1 {
      top: 20%;
      left: 60%;
    }
    .p2 {
      top: 35%;
      left: 20%;
    }
    .p3 {
      top: 50%;
      left: 45%;
    }
  }

  .cartAnime {
    position: absolute;
    left: 60%;
    bottom: 50%;
    transform: translateY(80%);
    @include vh(height, 900);
    @include maxScreen(md) {
      left: 0;
      max-width: none;
      bottom: 45%;
      height: 400px;
    }
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
