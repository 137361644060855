@font-face {
  font-family: 'Favorit';
  src: url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Book.woff')
      format('woff'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Book.oft')
      format('oft'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Book.woff2')
      format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Regular.woff')
      format('woff'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Regular.oft')
      format('oft'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Regular.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Medium.woff')
      format('woff'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Medium.oft')
      format('oft'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Medium.woff2')
      format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Bold.woff')
      format('woff'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Bold.oft')
      format('oft'),
    url('https://static.ciety.xyz/public/font/ABCFavoritHangul-Bold.woff2')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: CIETY-DISPLAY;
  src: url('../fonts/CIETY-DISPLAY-VariableVF.woff') format('woff'),
    url('../fonts/CIETY-DISPLAY-VariableVF.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: CIETY-BODY;
  src: url('../../../public/fonts/Ciety/CIETY-BODY-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: url('https://static.ciety.xyz/public/font/Pretendard-SemiBold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: url('https://static.ciety.xyz/public/font/Pretendard-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: url('https://static.ciety.xyz/public/font/Pretendard-Regular.woff2')
    format('woff2');
}
