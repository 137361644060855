@import '~/src/assets/scss/variables.scss';
.cut {
  width: 100%;
  .viewport {
    //height: var(--screen-height-px);
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
  }
}

.profile {
  width: 124px !important;
  height: 124px !important;
  max-width: none;
  max-height: none;

  @include maxScreen(md) {
    width: 74px !important;
    height: 74px !important;
  }
}

.container {
  @include container;
}

.chat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;

  p {
    border-radius: 16px;
    border: 1px solid rgba(28, 28, 36, 0.2);
    background: rgba(28, 28, 36, 0.06);
    backdrop-filter: blur(10px);
    @include font(16px, 500, 20.8px);
    color: $ciety-dk;
    padding: 12px 16px;
  }

  &.me {
    flex-direction: row-reverse;
  }
  &.ciety {
    p {
      border-radius: 16px;
      border: 1px solid var(--CIETY-WH, #fbfbfb);
      background: var(--Bright-60, rgba(251, 251, 251, 0.6));
      backdrop-filter: blur(10px);
    }
  }

  &.comma {
    p {
      background: $green;
    }
  }
  @include maxScreen(lg) {
    column-gap: 15px;
  }
  @include maxScreen(md) {
    column-gap: 10px;
    border-radius: 15px;
    p {
      @include font(14px, 350, 1.3);
      padding: 10px 15px;
      font-size: 14px;
      word-break: keep-all;
      border-radius: 15px;
      white-space: pre-wrap;
    }
  }
}
