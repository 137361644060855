@import '~/src/assets/scss/variables.scss';
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $green;
  @include maxScreen(md) {
    z-index: 100;
  }

  .wrapper {
    @include container;
    @include vh(padding-top, 40, 24);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    padding-top: 40px;
    @include maxScreen(md) {
      padding: 34px 29px 33px;
    }
  }

  .logo {
    svg {
      @include vh(height, 40, 36);
    }
  }

  .text {
    @include vh(font-size, 220);
    @include vh(line-height, 220);
    font-weight: 350;
    margin-bottom: 20px;

    @include maxScreen(md) {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 0;
    }
  }
}

.header {
  @include container;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.scene {
  position: fixed;
  @apply mx-auto bg-gray-dark;
  @apply inset-0;
  z-index: 2;
  min-height: var(--screen-height-px);
  max-height: var(--screen-height-px);
  overflow: hidden;
  &.full {
    position: relative;
    max-height: none;
    overflow: inherit;
  }
}

.scene1 {
  position: relative;
  .textLayer {
    @apply absolute top-0 left-0 right-0;
    @include container;
    z-index: 5;

    .heading {
      margin-top: 360px;
      color: #fbfbfb;
      @include maxScreen(md) {
        margin-top: 180px;
        text-align: center;
      }
    }

    .link {
      margin-top: 40px;
      .loginBtn {
        display: inline-block;
        @apply text-gray-dark bg-green;
        @include font(20px, 500, 1);
        border-radius: 20px;
        padding: 20px 40px;
      }
      @include maxScreen(xl) {
        margin-top: 28px;
        .loginBtn {
          @include font(15px, 500, 1);
          border-radius: 15px;
          padding: 15px 30px;
        }
      }
    }
  }

  .visualLayer {
    @apply absolute top-0 right-0 left-0;
    .characters {
      position: relative;
      @include container;
    }

    .greenScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      aspect-ratio: 800 / 550;
      background: rgba(178, 255, 0, 0.4);
      border-radius: 80px;
      z-index: 10;

      .text2 {
        @apply absolute top-1/2 left-1/2;
        transform: translate(-50%, -50%);
        .svg1 {
          @include vh(height, 231);
        }
        [data-index='1'] {
          @include vh(height, 231);
        }
        [data-index='2'] {
          @include vh(height, 308);
        }
        img {
          max-width: none;
        }
        @include maxScreen(xl) {
          [data-index='1'] {
            @include vh(height, 180);
          }
          [data-index='2'] {
            @include vh(height, 200);
          }
        }
      }
    }
  }
}

.layer2 {
  @include vh(padding-top, 100);
  position: relative;
  background: #b2ff00;
  z-index: 1;

  .text3 {
    font-family: Favorit;
    position: relative;
    text-align: center;
    color: $ciety-bk;
    @include font(48px, 700);
    &.en {
      font-weight: 500;
    }
    @include maxScreen(md) {
      p {
        font-size: 28px !important;
        white-space: pre-wrap !important;
      }
    }
  }

  .bubbles {
    position: relative;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
    width: 800px;
    row-gap: 20px;

    @include maxScreen(xl) {
      width: 650px;
    }

    @include maxScreen(lg) {
      width: 600px;
    }

    @include maxScreen(md) {
      width: auto;
      max-width: 400px;
      margin: 70px auto 0;
      padding: 0 20px;
    }
  }
}

.layer3 {
  @apply relative;
  z-index: inherit;

  .keyVisuals {
    width: 100%;
    z-index: 1;

    .sticky {
      @include viewport;
      overflow: hidden;
    }

    .cards {
      padding-bottom: 120px;
      position: relative;
      left: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((340 + 80) * 11px);

      .deck {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(210px);
      }

      img {
        display: inline;
        width: 340px;
        @include vw(margin-right, 80);
        &:last-child {
          margin-right: 0 !important;
        }
      }

      @include maxScreen(xl) {
        width: calc((255 + 60) * 11px);
        .deck {
          transform: translateX(calc(315px / 2));
        }
        img {
          width: 255px;
          margin-right: 60px;
        }
      }

      @include maxScreen(lg) {
        width: calc((255 + 40) * 11px);
        .deck {
          transform: translateX(calc(295px / 2));
        }
        img {
          width: 255px;
          margin-right: 40px;
        }
      }

      @include maxScreen(md) {
        width: calc((165 + 36) * 11px);
        .deck {
          transform: translateX(100px);
        }
        img {
          width: 165px;
          margin-right: 36px;
        }
        img:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .background {
    @apply absolute inset-0;
    z-index: 2;
  }

  .mainCard {
    position: relative;
    z-index: 10;
    aspect-ratio: 566 / 1000;
    @include vh(min-height, 600);
    @include vw(margin-right, 80);
    @include maxScreen(xl) {
      min-height: 0;
      height: 600px;
      margin-right: 36px;
    }
    @include maxScreen(lg) {
      min-height: 0;
      height: 450px;
      margin-right: 36px;
    }
    @include maxScreen(md) {
      min-height: 0;
      height: 320px;
      margin-right: 36px;
    }
    .bg {
      position: absolute;
      top: 5%;
      bottom: 13%;
      left: 7%;
      right: 7%;
    }
    > img {
      @include vh(height, 600);
      @include maxScreen(md) {
        height: 600px;
      }
      @include maxScreen(lg) {
        height: 450px;
      }
      @include maxScreen(md) {
        height: 320px;
      }
    }
    .gameComma {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      svg {
        width: 100%;
        height: 100%;
      }
      img {
        position: absolute;
        left: 50%;
        bottom: 5%;
        width: 70%;
        height: auto;
        transform: translateX(-50%);
      }
    }
  }

  .contents {
    position: relative;
    z-index: 3;
    .sticky {
      position: sticky;
      top: 50%;
    }
  }

  .feed {
    margin-top: 200px;
    overflow: hidden;
    .visual {
      @include vh(margin-top, 120);
      position: relative;
      text-align: center;

      > img {
        @include vw(width, 500);
        margin: 0 auto;
      }

      @include maxScreen(xl) {
        padding: 0 40px;
        > img {
          width: 100%;
          max-width: 375px;
          margin: 0 auto;
        }
      }

      @include maxScreen(md) {
        padding: 0 40px;
        > img {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
        }
      }

      .bubble {
        @apply flex justify-center items-center;
        @include vw(column-gap, 20);
        position: absolute;

        @include maxScreen(xl) {
          column-gap: 15px;
        }

        @include maxScreen(md) {
          column-gap: 10px;
        }

        .thumbnail {
          flex-shrink: 0;
        }

        .thumbnail img {
          @include vw(width, 150);
          @include maxScreen(md) {
            width: 74px;
          }
        }

        p {
          @include rw_font(16, 500);
          padding: rw(12px) rw(16px);
          background: #fefefe1a;
          backdrop-filter: blur(20px);
          border-radius: 30px;
          white-space: pre-wrap;
          text-align: left;
          color: $ciety-wh;
          @include maxScreen(md) {
            @include font(14px, 350, 1.3);
            padding: 10px 15px;
            white-space: normal;
            backdrop-filter: none;
            border-radius: 15px;
          }
        }

        &.green {
          p {
            background: #b2ff00;
            color: $ciety-bk;
          }
        }

        &.comma {
          flex-direction: row-reverse;
          @include maxScreen(md) {
            flex-direction: row;
          }
        }

        &:nth-child(1) {
          top: 43%;
          left: 50%;
          @include maxScreen(md) {
            top: 13%;
            left: 5%;
            p {
              white-space: pre-wrap !important;
            }
          }
        }
        &:nth-child(2) {
          top: 20%;
          left: 50%;
          transform: translateX(-147%);
          @include maxScreen(md) {
            top: 27%;
            left: auto;
            right: -3%;
            transform: none;
            flex-direction: row-reverse;
            p {
              min-width: 150px;
              white-space: pre-wrap !important;
            }
          }
        }
        &:nth-child(3) {
          top: 72%;
          left: 50%;
          transform: translateX(37%);
          @include maxScreen(md) {
            top: 45%;
            left: 35%;
            transform: none;
            p {
              min-width: 180px;
              white-space: pre-wrap !important;
            }
          }
        }
        &:nth-child(4) {
          top: 20%;
          left: 50%;
          transform: translateX(70%);
          @include maxScreen(md) {
            top: 60%;
            left: -1%;
            transform: none;
            p {
              //min-width: 200px;
              white-space: pre-wrap !important;
            }
          }
        }
        &:nth-child(5) {
          top: 50%;
          left: 50%;
          transform: translateX(-130%);
          @include maxScreen(md) {
            top: 77%;
            left: auto;
            right: 5%;
            transform: none;
            p {
              min-width: 200px;
              white-space: pre-wrap !important;
            }
            .thumbnail {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .giftok {
    position: relative;
    z-index: 3;
    .player {
      position: relative;
      margin: 120px auto 0;
      z-index: 10;
    }
    .giftokPlayer {
      width: 1400px;
      margin: auto;
      img {
        max-width: 100%;
      }
    }
    @include maxScreen(xl) {
      .giftokPlayer {
        width: 1040px;
      }
    }
    @include maxScreen(lg) {
      .giftokPlayer {
        width: 720px;
      }
    }
    @include maxScreen(md) {
      .giftokPlayer {
        width: 300px;
      }
    }

    .sticky {
      position: sticky;
      top: 50%;
    }

    .chatList {
      position: fixed;
      left: calc(50% - 150px);
      bottom: 0;

      .chat {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
        > img {
          margin-right: 10px;
        }
        p {
          display: inline-block;
          padding: 10px 15px;
          border-radius: 15px;
          white-space: pre-wrap;
          background: rgba(251, 251, 251, 0.7);
        }
        .nft {
        }
        .giftok {
          margin-left: 85px;
          width: 162px !important;
        }
      }
    }
  }

  .animate {
    position: fixed;
    top: 40%;
    z-index: 3;
    img {
      @include vh(height, 840, 800);
      width: auto;
      max-width: none;
    }
    @include maxScreen(xl) {
      left: 0;
    }
    @include maxScreen(lg) {
      left: 0;
      img {
        height: 630px;
      }
    }
    @include maxScreen(md) {
      img {
        height: 420px;
      }
    }
  }

  .invite {
    position: relative;
    margin-top: 400px;
    z-index: 3;

    @include maxScreen(md) {
      .heading p {
        br {
          display: none;
        }
      }
    }

    .chatList {
      margin: 80px auto 0;
      max-width: 600px;
      @include maxScreen(md) {
        padding: 0 20px;
        max-width: 400px;
      }

      .chat {
        @apply flex justify-start items-start;
        @include vw(column-gap, 20);
        @include vh(margin-bottom, 40);
        @include maxScreen(xl) {
          column-gap: 20px;
        }
        @include maxScreen(lg) {
          column-gap: 15px;
        }
        @include maxScreen(md) {
          column-gap: 10px;
        }
        .thumb {
          flex-shrink: 0;
          img {
            @include vw(width, 150);
          }
        }
        p {
          display: inline-block;
          @include rw_font(16, 350, 1.3);
          margin-bottom: 10px;
          color: $darkGray;
          padding: 12px 16px;
          background: rgba(251, 251, 251, 0.8);
          backdrop-filter: blur(20px);
          white-space: pre-wrap;
          border-radius: 16px;
          @include maxScreen(md) {
            @include font(14px, 350, 1.3);
            padding: 10px 15px;
            border-radius: 15px;
            white-space: pre-wrap;
            max-width: 240px;
          }
        }
        .nft {
          img {
            width: 100%;
          }
        }
        .lock {
          margin-left: 10px;
          img {
            @include vw(width, 150);
          }
        }
        &.group {
          flex-direction: row-reverse;
          .thumb {
            @apply flex;
            img:not(:last-child) {
              margin-right: -25px;
            }
          }
        }
      }
    }

    .sticky {
      position: sticky;
      top: -250px;
      max-width: 800px;
      margin: 80px auto 0;
      transition: top 0.5s;

      @include maxScreen(xl) {
        top: -30px;
        &.transit {
          top: -230px;
        }
      }

      @include maxScreen(md) {
        top: -140px;
        &.transit {
          top: -195px;
        }
      }

      .joinPopup {
        @apply flex flex-col justify-center items-center;
        @include vh(padding-top, 40);
        @include vh(padding-bottom, 40);
        background: rgba(28, 28, 36, 0.7);
        border-radius: 60px;
        max-width: 800px;
        margin: 0 auto;

        @include maxScreen(xl) {
          max-width: 600px;
        }

        @include maxScreen(md) {
          max-width: 300px;
          margin: 0 auto;
          border-radius: 30px;
        }

        .profileImg {
          @include vh(margin-bottom, 16);
        }
        h3 {
          @include vh(margin-bottom, 16);
          @include r_font(32, 350, 1.3);
          color: #ffffff;
          @include maxScreen(md) {
            @include font(16px, 350, 1.3);
            margin-bottom: 8px;
          }
        }
        p {
          @include vh(margin-bottom, 26);
          @include r_font(20, 350, 1.3);
          color: #ffffff;
          @include maxScreen(md) {
            @include font(12px, 350, 1.5);
            margin-bottom: 8px;
          }
        }
        .ticket {
          @include vh(margin-top, 36);
          @include vh(margin-bottom, 36);
          @include vw(width, 180);

          @include maxScreen(xl) {
            width: 135px;
            margin: 18px auto;
          }

          @include maxScreen(md) {
            width: 90px;
            margin: 18px auto;
          }
        }
        .join {
          @apply flex justify-center items-center;
          @include vh(padding-top, 20);
          @include vh(padding-bottom, 20);
          @include vw(padding-left, 40);
          @include vw(padding-right, 40);
          @include vw(column-gap, 12);
          @include r_font(20, 500, 1);
          color: #1c1c24;
          background: #fbfbfb;
          border-radius: 999px;
          img {
            @include vh(height, 20);
          }
          @include maxScreen(xl) {
            padding: 15px 30px;
            white-space: nowrap;
            @include font(15px, 500, 1);
            img {
              height: 15px;
              margin-right: 9px;
            }
          }
          @include maxScreen(md) {
            padding: 10px 20px;
            white-space: nowrap;
            @include font(12px, 500, 1);
            img {
              height: 12px;
              margin-right: 6px;
            }
          }
        }
      }

      .invitees {
        @apply flex justify-center items-end;
        margin-top: 40px;
        column-gap: 40px;

        @include maxScreen(xl) {
          column-gap: 20px;
        }

        @include maxScreen(md) {
          column-gap: 10px;
        }

        .invitee {
          @apply flex-col justify-center items-center;
          display: none;
          animation-name: fadeIn;
          animation-duration: 0.35s;
          animation-fill-mode: forwards;
          opacity: 0;

          &:nth-child(1) {
            animation-delay: 0s;
          }
          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }

          .check {
            @include vh(height, 30);
            @include vh(margin-bottom, 40);
            img {
              display: none;
              @include vh(height, 30);
              animation-name: fadeIn;
              animation-duration: 0.35s;
              animation-fill-mode: forwards;
              opacity: 0;
            }
          }
          &.checked {
            .check img {
              display: block;
            }
          }
        }
        &.show {
          .invitee {
            display: flex;
          }
        }
      }
    }
  }

  .transition {
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    opacity: 0;
    transition: opacity 0.35s;

    &.show {
      opacity: 1;
    }

    .text {
      @include r_font(28, 350, 1.3);
      position: relative;
      text-align: center;
      color: #1c1c24;
      white-space: nowrap;
      padding: 20px 20px;
      z-index: 5;
      opacity: 1;
      transition: opacity 0.35s;
      &.hide {
        opacity: 0;
      }
      @include maxScreen(md) {
        white-space: pre-wrap;
        @include font(14px, 400);
        padding: 0;
      }
    }

    .text2 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 10;
      opacity: 0;
      transition: opacity 0.35s;
      &.show {
        opacity: 1;
      }
    }

    .bg {
      @include r_font(28, 350, 1.3);
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100vw;
      height: 110vh;
      transform: translate(-50%, -50%);
      overflow: hidden;
      z-index: 3;
      p {
        position: relative;
        left: 50%;
        top: 50%;
        text-align: center;
        display: inline-block;
        color: transparent;
        padding: 20px 40px;
        white-space: nowrap;
        border-radius: 1000px 1000px 1000px 1000px;
        background: #1aaefc;
        @include maxScreen(md) {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.layer4 {
  position: relative;
  background: #1aaefc;
  z-index: 14;

  .ending {
    //margin-top: 1200px;
    position: relative;
    color: #fbfbfb;
  }
}
