@function rw($value) {
  @return min(calc($value * (var(--screen-width-window) / 1920)), $value);
}

@function rwM($value) {
  @return calc($value * (var(--screen-width-window) / 375));
}

@mixin screenHeight($mh: 1080px) {
  height: var(--screen-height-window);
  min-height: $mh;
  @include maxScreen(md) {
    min-height: auto;
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin rw_font($fs: 16px, $fw: 500, $lh: 1.35) {
  font-size: rw($fs);
  font-weight: $fw;
  line-height: $lh;
}

@mixin container {
  padding: 0 30px;
  max-width: 1600px;
  margin: 0 auto;
  @include maxScreen(md) {
    padding: 0 15px;
  }
}

@mixin font($fs: 16, $fw: 400, $lh: 1.35) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}

@mixin vh($prop, $value, $min: 1) {
  #{$prop}: #{$value}px;
  @include maxScreen(xl) {
    #{$prop}: min(
      calc(#{$value}px * (var(--screen-height) / 1440)),
      #{$value}px
    );
  }
}

@mixin vw($prop, $value, $min: 1, $unit: px) {
  #{$prop}: #{$value}px;

  @include maxScreen(xl) {
    @if $value > 0 {
      #{$prop}: max(
        calc(#{$value}#{$unit} * (var(--screen-width) / 2560)),
        #{$min}#{$unit}
      );
    } @else {
      #{$prop}: min(
        calc(#{$value}#{$unit} * (var(--screen-width) / 2560)),
        #{$min}#{$unit}
      );
    }
  }
  @include maxScreen(lg) {
    @if $value > 0 {
      #{$prop}: max(
        calc(#{$value}#{$unit} * (var(--screen-width) / 2560)),
        1#{unit}
      );
    } @else {
      #{$prop}: min(
        calc(#{$value}#{$unit} * (var(--screen-width) / 2560)),
        1#{unit}
      );
    }
  }
}

@mixin r_font($fs: 16, $fw: 400, $lh: 1.35) {
  @include vh(font-size, $fs);
  font-weight: $fw;
  line-height: $lh;
}

@mixin maxScreen($size: lg) {
  @if $size == md {
    @media screen and (max-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media screen and (max-width: 1440px) {
      @content;
    }
  } @else if $size == xl {
    @media screen and (max-width: 1920px) {
      @content;
    }
  } @else if $size == xxl {
    @media screen and (max-width: 2560px) {
      @content;
    }
  }
}

@mixin minScreen($size: lg) {
  /*
  sm: '480px',
  md: '768px',
  lg: '1440px',
  xl: '1920px',
  xxl: '2560px',
  */
  @if $size == sm {
    @media screen and (min-width: 480px) {
      @content;
    }
  } @else if $size == md {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media screen and (min-width: 1440px) {
      @content;
    }
  } @else if $size == xl {
    @media screen and (min-width: 1920px) {
      @content;
    }
  } @else if $size == xxl {
    @media screen and (min-width: 2560px) {
      @content;
    }
  }
}

@mixin title() {
  @include r_font(80, 350, 1);
  color: #1c1c24;
  @include maxScreen(md) {
    @include font(28px, 350, 1.2);
    white-space: pre-wrap;
    text-align: center;
  }
}

@mixin desc() {
  @include r_font(20, 350, 1.5);
  color: #1c1c24;
  @include maxScreen(md) {
    @include font(14px, 350, 1.5);
    white-space: pre-wrap;
    text-align: center;
  }
}

@mixin viewport() {
  position: sticky;
  top: 0;
  //height: var(--screen-height-px);
  height: 100vh;
}

@function ch($value, $min: 0, $vh: 1440) {
  @if ($value >= 0) {
    @return max(calc(#{$value}px * var(--h-ratio)), #{$min}px);
  } @else {
    @return min(calc(#{$value}px * var(--h-ratio)), #{$min}px);
  }
}

@function ch_m($value, $min: 0, $vh: 640) {
  @if ($value >= 0) {
    @return max(calc(#{$value}px * var(--h-ratio)), #{$min}px);
  } @else {
    @return min(calc(#{$value}px * var(--h-ratio)), #{$min}px);
  }
}

@function cw($value, $min: 0, $vw: 2560) {
  @if ($value >= 0) {
    @return max(calc(#{$value}px * var(--w-ratio)), #{$min}px);
  } @else {
    @return min(calc(#{$value}px * var(--w-ratio)), #{$min}px);
  }
}

@function cw_m($value, $min: 0, $vw: 375) {
  @if ($value >= 0) {
    @return max(calc(#{$value}px * var(--w-ratio)), #{$min}px);
  } @else {
    @return min(calc(#{$value}px * var(--w-ratio)), #{$min}px);
  }
}

@function cx($value, $min: 0) {
  @if ($value >= 0) {
    @return max(calc(#{$value}px * var(--ratio)), #{$min}px);
  } @else {
    @return min(calc(#{$value}px * var(--ratio)), #{$min}px);
  }
}

$darkGray: #1c1c24;
$orange: #ff9300;
$sky: #1aaefc;
$green: #b2ff00;
$green-dark: #287846;
$gray-dark: #1c1c24;
$transparent: transparent;

$ciety-gray-10: #f0f0f0;
$ciety-og: #ff9300;
$ciety-bk: #13131a;
$ciety-dk: #1c1c24;
$ciety-wh: #fbfbfb;
$ciety-bright-10: #fefefe1a;
$ciety-lg: #b2ff00;
$ciety-lg-80: #cdff8c;
$ciety-sk: #1aaefc;
$ciety-br: #b07f4a;
$ciety-rb: #b2363c;
$ciety-lv: #c6aeed;
$ciety-bl: #6e96ed;
$ciety-bl-20: #e2eafb;
$ciety-lp: #f8bbed;
$ciety-dg: #287846;
$ciety-bl: #6e96ed;
$ciety-yl-50: #fefe7f;
$ciety-dk-80: #46464d;
$ciety-rd-dk: #cd3849;
$ciety-gy-30: #e7e7e7;
$ciety-dk-60: #77777c;
$ciety-gy: #b1b1b1;
$ciety-gy-10: #F0F0F0;
$ciety-bright-60: #fbfbfb99;
$ciety-bright-80: #fefefecc;
$ciety-dim-60: #1C1C2499;
