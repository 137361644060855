@import '~/src/assets/scss/variables.scss';
.iconTitleContainer {
  color: $ciety-bk;
  font-family: 'Favorit';
  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .iconWrapper {
    height: 45px;
    margin-bottom: 12px;
    @include maxScreen(md) {
      height: 30px;
      margin-bottom: 8px;
    }
    svg,
    img {
      height: 100%;
    }
  }
  .subTitle {
    @include font(16px, 500);
    margin-bottom: 24px;
    @include maxScreen(md) {
      @include font(24px, 500);
      margin-bottom: 20px;
    }
  }
  .title {
    @include font(50px, 700);
    margin-bottom: 30px;
    &.en {
      font-weight: 500;
    }
    @include maxScreen(md) {
      @include font(24px, 700);
      margin-bottom: 20px;
    }
  }
  .desc {
    @include font(20px, 500);
    @include maxScreen(md) {
      @include font(14px, 500);
    }
  }
}
