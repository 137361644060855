@import '~/src/assets/scss/variables.scss';
//Tap
.tap {
  display: flex;
  gap: 12px;
  li {
    cursor: pointer;
    position: relative;
    @include rw_font(16px);
    @include maxScreen(md) {
      @include font(14px);
    }
    .underline {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      &.black {
        background: $ciety-bk;
      }
      &.yellow {
        background: $ciety-yl-50;
      }
      &.red {
        background: $ciety-lp;
      }
    }
  }
}

//SectionTitle
.title {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include maxScreen(md) {
    align-items: center;
    text-align: center;
  }
  &.center {
    align-items: center;
    text-align: center;
  }
  &.black {
    color: $ciety-bk;
  }
  &.white {
    color: $ciety-wh;
  }
  &.yellow {
    color: $ciety-yl-50;
  }
  &.red {
    color: $ciety-lp;
  }
  &.green {
    color: $ciety-lg;
  }
  .subTitle {
    font-family: 'Favorit';
    @include rw_font(16px);
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Favorit';
    @include rw_font(48px, 700, 120%);
    margin-bottom: 24px;
    &.en {
      font-weight: 500;
    }
    @include maxScreen(md) {
      @include font(24px, 700);
      margin-bottom: 4px;
    }
  }
  .desc {
    @include rw_font(18px);
    @include maxScreen(md) {
      @include font(14px, 500);
      white-space: pre-wrap;
    }
  }
}

//Button
.button {
  font-family: Pretendard;
  border-radius: 999px;
  width: fit-content;
  margin-top: 40px;
  transition: 0.3s ease-in-out;
  &.en {
    font-family: Favorit;
  }
  &.default {
    @include rw_font(16px);
    padding: rw(12px) rw(24px);
  }
  &.bold {
    @include rw_font(18px);
    padding: rw(14px) rw(25px);
  }
  &.black {
    background-color: $ciety-bk;
    color: $ciety-wh;
    @include canHover {
      &:hover {
        background-color: $ciety-lg;
        color: $ciety-bk;
      }
    }
  }
  &.green {
    background-color: $ciety-lg;
    color: $ciety-bk;
    @include canHover {
      &:hover {
        background-color: $ciety-wh;
        color: $ciety-bk;
      }
    }
  }
  &.white {
    background-color: $ciety-wh;
    color: $ciety-bk;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.skyblue {
    background-color: $ciety-wh;
    color: $ciety-sk;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.brown {
    background-color: $ciety-yl-50;
    color: $ciety-br;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.red {
    background-color: $ciety-lp;
    color: $ciety-rd-dk;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.blue {
    background-color: $ciety-wh;
    color: $ciety-bl;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.yellow {
    background-color: $ciety-yl-50;
    color: $ciety-dg;
    @include canHover {
      &:hover {
        background-color: $ciety-bk;
        color: $ciety-wh;
      }
    }
  }
  &.purple {
    background-color: $ciety-bk;
    color: $ciety-lv;
    @include canHover {
      &:hover {
        background-color: $ciety-wh;
        color: $ciety-bk;
      }
    }
  }
  &.pink {
    background-color: $ciety-bk;
    color: $ciety-lp;
    @include canHover {
      &:hover {
        background-color: $ciety-wh;
        color: $ciety-bk;
      }
    }
  }
  &.blackAndWhite {
    background-color: $ciety-bk;
    color: $ciety-wh;
    @include canHover {
      &:hover {
        background-color: $ciety-wh;
        color: $ciety-bk;
      }
    }
  }
  @include maxScreen(md) {
    margin-top: 24px;
    &.default {
      @include font(16px, 500);
      padding: 12px 28px;
    }
    &.bold {
      @include font(16px, 500);
      padding: 12px 32px;
    }
  }
}

//subdomainForm
form {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  width: fit-content;
  &.center {
    justify-content: center;
    margin: 32px auto 0;
  }
  .inputContainer {
    font-font: 'Favorit';
    width: rw(240px);
    height: rw(56px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $ciety-wh;
    border-radius: 8px;
    padding: 0 24px;
    .inputWrapper {
      display: flex;
      gap: 2px;
    }
    input {
      @include rw_font(16px, 500);
      background: transparent;
      width: rw(75px);
      color: $ciety-bk;
      &::placeholder {
        color: $ciety-gy;
      }
      &:focus {
        outline: none;
      }
    }
    .root {
      color: $ciety-dk;
      @include rw_font(16px, 500);
    }
    svg {
      width: rw(16px);
      height: rw(16px);
      margin-right: 0;
    }
  }
  .toolTip {
    z-index: 2;
    position: absolute;
    top: 60px;
    left: 90px;
    background: #13131a99;
    color: $ciety-wh;
    @include rw_font(14px, 500);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    padding: rw(12px) rw(16px);
    width: fit-content;
    &.white {
      background: $ciety-bright-10;
    }
  }
}

.defaultImg {
  -webkit-user-drag: none;
}
