@import '~/src/assets/scss/variables.scss';
.layout {
  position: relative;
  background: #1aaefc;
  transition: background-color 0.5s;
  &.brown {
    background: #b07f4a;
  }
  &.purple {
    background: #c6aeed;
  }
  &.black {
    background: #1c1c24;
  }
}

.header {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  @include container;
  padding-top: 20px;
  z-index: 10;

  .sticky {
    position: sticky;
    top: 20px;
  }
}

.container {
  @include container;
}

.heading {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  color: $gray-dark;
  z-index: 2;

  h2 {
    @include font(80px, 350, 1);
    @include vh(margin-bottom, 40, 24);
  }

  p {
    @include font(20px, 350, 1.5);
    @include vh(margin-bottom, 40);
  }

  @include maxScreen(xl) {
    @include vh(padding-top, 170, 170);

    h1 {
      @include font(60px, 350, 1);
      @include vh(margin-bottom, 30, 24);
    }
    p {
      @include font(15px, 350, 1.5);
      @include vh(margin-bottom, 28, 30);
    }
  }

  @include maxScreen(md) {
    h2 {
      @include font(28px, 350, 1.2);
      white-space: pre-wrap;
      text-align: center;
    }
    p {
      @include font(14px, 400, 1.5);
      white-space: pre-wrap;
      text-align: center;
    }
  }
}

.layer1 {
  position: relative;
  top: 0;
  height: 130vh;

  @include maxScreen(md) {
    height: 150vh;
  }

  .titleWrapper {
    @include container;
    padding-top: 302px;
    position: relative;
    z-index: 2;
  }

  .heading {
    @include vh(padding-top, 227, 170);
    @include maxScreen(md) {
      padding-top: 104px;
    }
  }

  .visual {
    @apply absolute inset-0;
    z-index: 1;

    .sticky {
      position: sticky;
      top: 0;
      width: 100%;
      height: var(--screen-height-px);
    }

    .mask {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: var(--screen-height-px);
      transform: translate(-50%, -50%);
      background: $green;
      overflow: hidden;
    }

    .img {
      @include container;
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: var(--screen-height-px);
      text-align: center;
    }

    img {
      @apply absolute;
      @include vw(max-width, 1400, 1400);
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 100%;
      @include maxScreen(xl) {
        width: 80%;
      }
      @include maxScreen(md) {
        left: 50%;
        width: 648px;
        max-width: none;
      }
    }
  }
}

.layer2 {
  .heading {
    @include vw(padding-top, 200);
    @include maxScreen(xl) {
      padding-top: 120px;
    }
    @include maxScreen(md) {
      padding-top: 160px;
    }
    h2 {
      @include title;
      text-align: center;
      @include maxScreen(md) {
        margin-top: 104px;
      }
    }
    .features {
      @include vh(margin-top, 40);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      .feat {
        @include desc;
        text-align: center;
        .num {
          color: #f8bbed;
          margin-bottom: 0;
        }
        img {
          display: none;
        }
      }
      @include maxScreen(md) {
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        margin-top: 20px;
        .feat {
          text-align: center;
          img {
            max-width: 100%;
            display: block;
            margin: 20px auto 0;
          }
        }
      }
    }
  }

  .featureCards {
    @apply flex justify-center items-center;
    @include vw(column-gap, 40);
    @include vh(margin-top, 80);
    overflow: hidden;
    img {
      @include vw(width, 440);
    }
    @include maxScreen(xl) {
      column-gap: 30px;
      img {
        width: 330px;
      }
    }
    @include maxScreen(md) {
      display: none;
    }
  }
}

.layer3 {
  @apply relative;

  @include maxScreen(md) {
    margin-top: 100px;
  }

  .titleWrapper {
    padding-top: 220px;
    @include maxScreen(md) {
      padding-top: 160px;
    }
  }

  .heading {
    @include vw(margin-top, 100);
    @include vw(padding-top, 100);
    text-align: center;
    h2 {
      font-weight: 350;
      font-size: 80px;
      line-height: 100%;
    }
    @include maxScreen(xl) {
      padding-top: 160px;
      h2 {
        font-size: 60px;
      }
    }
    @include maxScreen(lg) {
      padding-top: 120px;
      h2 {
        font-size: 40px;
      }
    }
    @include maxScreen(md) {
      padding-top: 0;
      h2 {
        font-size: 28px;
      }
      p {
        white-space: normal;
      }
      p + p {
        margin-top: 20px;
      }
    }
  }

  .image {
    @include vh(margin-top, 80);
    height: 140vh;

    .mask {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: var(--screen-height-px);
      transform: translate(-50%, -50%);
      border-radius: 0;
      overflow: hidden;
      &.purple {
        background: #c6aeed;
      }
    }

    img {
      @apply absolute;
      height: 100vh;
      max-width: none;
      aspect-ratio: 1844 / 1420;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      object-fit: contain;
      @include maxScreen(xl) {
        height: var(--screen-height-px);
      }
      @include maxScreen(md) {
        width: auto;
        max-width: none;
      }
    }
  }
}

.layer4 {
  .container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      @include vw(padding-top, 200);
      color: #fff;
      h2 {
        font-weight: 350;
        font-size: 80px;
        line-height: 100%;
      }
      p {
        margin-bottom: 0;
      }
      @include maxScreen(md) {
        padding-top: 160px;
        h2 {
          font-family: Favorit;
          @include font(24px, 700, 1.3);
        }
        p {
          @include font(14px, 500, 1.4);
        }
        p + p {
          margin-top: 20px;
        }
      }
    }
  }
  .image {
    margin-top: 80px;
    margin-bottom: 80px;
    overflow: hidden;
    .wrap {
      @apply inline-flex justify-start items-center flex-nowrap;
      img {
        @include vw(width, 450);
        aspect-ratio: 450 / 600;
        margin-right: 10px;
      }
      @include maxScreen(md) {
        img {
          width: auto;
          height: 300px;
          aspect-ratio: 450 / 600;
        }
      }
    }
  }
}

.footer {
  @include vw(margin-top, 130);
}
