@import '~/src/assets/scss/variables.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts.scss';

:root {
  --h-ratio: var(--screen-height) / 1440;
  --w-ratio: var(--screen-width) / 2560;
  --ratio: min(var(--h-ratio), var(--w-ratio));
}

html {
  &[data-scrollable='true'] {
    height: var(--screen-height-px);
    overflow: hidden;
  }
}

html,
body {
  font-family: 'Pretendard', ui-sans-serif, system-ui, -apple-system, Arial,
    Noto Sans, sans-serif;
}

img {
  max-width: none;
  max-height: none;
}

body {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 2px;
    width: 2px;
    border-width: 2px;
    background-color: #222;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  * {
    white-space: pre-wrap;
  }

  //@include maxScreen(md) {
  //  * {
  //    white-space: normal;
  //  }
  //}
}

.header {
}
